import React, { useEffect, useState, ReactNode, cloneElement, isValidElement, ReactElement } from 'react';
import { ArrayInput, FormDataConsumer, minLength, required, SimpleFormIterator } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { Box, Grid, Typography, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { minArrayLength } from 'src/utils/validate';
import { Theme } from 'src/Theme';
import { ShsSelectArrayInput } from 'src/components/common/Fields/ShsSelectArrayInput';
import { ChipLabel } from '../components/ChipLabel';
import useEducationalServices from '../useEducationalServices';
import AutoCompleteSubject from '../components/AutoCompleteSubject';
import { CIVIL_LAW_NATURE_EMPLOYEES, FULL_TIME_EMPLOYEES } from '../const/roles';
import { ShsButton, ShsCheckbox, ShsDateInput, ShsDatePicker, ShsSelectInput } from '../../common';
import { ReactComponent as DeleteIcon } from '../../../assets/trash-can.svg';
import { disableFirstRemoveStyle, formIteratorStyles } from '../../../styles';
import { UserRole } from '../../../auth/types';
import DaySchedule from './DaySchedule';
import { DaysOfWeek, DaysOfWeekLabel } from '../../../constants';
import ShsAutoCompleteWithLoad from '../../common/Fields/ShsAutoCompleteWithLoad';
import { StaffRate, StaffRateHours, StaffRateLabel, UserRoleLabel } from '../../../constants';

/**
 * Props for the DisabledWrapper component
 */
interface DisabledWrapperProps {
    isDisabled: boolean;
    children: ReactNode;
}

/**
 * Wrapper component that disables its children based on the isDisabled prop
 */
const DisabledWrapper: React.FC<DisabledWrapperProps> = ({ isDisabled, children }) => {
    if (isDisabled) {
        return (
            <div style={{ opacity: 0.5, pointerEvents: 'none' }}>
                {React.Children.map(children, (child) => {
                    if (isValidElement(child)) {
                        return cloneElement(child as ReactElement<any>, { disabled: true });
                    }
                    return child;
                })}
            </div>
        );
    }
    return <>{children}</>;
};

/**
 * Component for managing employee employment types
 */
const EmploymentTypeForm: React.FC = () => {
    const [isAddButtonActive, setIsAddButtonActive] = useState(false);
    return (
        <ArrayInput source="employeeCenters" label="" defaultValue={[{}]}>
            <SimpleFormIterator
                fullWidth
                disableClear
                disableReordering
                sx={{
                    ...disableFirstRemoveStyle,
                    ...formIteratorStyles,
                    '& .RaSimpleFormIterator-line': {
                        flexDirection: 'column',
                        background: Theme.palette.primary.creamyWhite,
                        padding: '0 32px 0 32px'
                    },
                    '& .RaSimpleFormIterator-list': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px'
                    },
                    '& .RaSimpleFormIterator-buttons': {
                        background: Theme.palette.primary.creamyWhite,
                        marginTop: '20px',
                        padding: '0 32px 0 32px'
                    },
                    '& .RaSimpleFormIterator-action': {
                        visibility: 'visible',
                        borderTop: '1px solid #D9D9D9',
                        padding: '24px 0px'
                    }
                }}
                getItemLabel={(index) => (
                    <Typography
                        variant="h5"
                        component="span"
                        mb={1}
                        mt={1}
                        fontWeight="600"
                        color={Theme.palette.primary.midnightBlue}>
                        {`Працевлаштування - ${index + 1}`}
                    </Typography>
                )}
                removeButton={
                    <ShsButton
                        styleType="text-danger"
                        sx={{ p: 0, mb: 1 }}
                        label="Видалити працевлаштування"
                        icon={<DeleteIcon />}
                    />
                }
                // disableAdd={!isAddButtonActive}
                addButton={
                    <ShsButton
                        styleType="text"
                        sx={{ paddingLeft: '0', pt: '2', width: '100%', display: 'flex', justifyContent: 'start' }}
                        label="Додати працевлаштування"
                        icon={<AddCircleOutlineIcon />}
                        disabled={!isAddButtonActive}
                    />
                }>
                <FormDataConsumer>
                    {({ getSource }) => {
                        const [isEducationCenterSelected, setIsEducationCenterSelected] = useState(false);
                        const { setValue } = useFormContext();

                        const educationCenterId = useWatch({
                            name: getSource('educationCenterId'),
                            defaultValue: null
                        });
                        const isStaffMember = useWatch({
                            name: getSource('staffMember'),
                            defaultValue: false
                        });
                        const isCivilLawContract = useWatch({
                            name: getSource('civilLawNatureContract'),
                            defaultValue: false
                        });
                        const roles = useWatch({
                            name: getSource('roles'),
                            defaultValue: []
                        });
                        const rate = useWatch({
                            name: getSource('rate'),
                            defaultValue: []
                        });
                        const schedule = useWatch({
                            name: getSource('possibleWorkSchedule'),
                            defaultValue: {}
                        });
                        const dateOfStarting = useWatch({
                            name: getSource('dateOfStarting'),
                            defaultValue: ''
                        });

                        const dateOfStartingCLNC = useWatch({
                            name: getSource('dateOfStartingCLNC'),
                            defaultValue: ''
                        });

                        const dateOfEnding = useWatch({
                            name: getSource('dateOfEnding'),
                            defaultValue: ''
                        });

                        const rolesCLNC = useWatch({
                            name: getSource('rolesCLNC'),
                            defaultValue: []
                        });

                        const subjectIds = useWatch({
                            name: getSource('subjectIds'),
                            defaultValue: []
                        });

                        useEffect(() => {
                            const newIsSelected = !!educationCenterId;
                            setIsEducationCenterSelected(newIsSelected);
                        }, [educationCenterId]);

                        useEffect(() => {
                            const isStaffMemberFormValid = isStaffMember && roles.length > 0 && dateOfStarting && rate;
                            const isCivilLawContractFormValid =
                                isCivilLawContract &&
                                dateOfStartingCLNC &&
                                dateOfEnding &&
                                rolesCLNC.length > 0 &&
                                subjectIds.length > 0;
                            //condition check if both form is active
                            const shouldButtonBeActive =
                                isStaffMember && isCivilLawContract
                                    ? isStaffMemberFormValid && isCivilLawContractFormValid
                                    : isStaffMemberFormValid || isCivilLawContractFormValid;
                            console.log(roles);
                            setIsAddButtonActive(shouldButtonBeActive);
                        }, [
                            isStaffMember,
                            isCivilLawContract,
                            roles,
                            dateOfStarting,
                            rate,
                            dateOfStartingCLNC,
                            dateOfEnding,
                            rolesCLNC,
                            subjectIds
                        ]);

                        const handleEducationCenterChange = (value: any, option: any) => {
                            setIsEducationCenterSelected(!!value);
                        };

                        const {
                            selectedItems,
                            filterChoices,
                            setSelectedItems,
                            handleRemoveService,
                            sortOptions,
                            groupByCategory
                        } = useEducationalServices(getSource(''));

                        const clearStaffMemberFields = () => {
                            setValue(getSource('roles'), []);
                            setValue(getSource('dateOfStarting'), null);
                            setValue(getSource('rate'), '');
                            setValue(getSource('possibleWorkSchedule'), {});
                        };

                        const clearCivilLawFields = () => {
                            setValue(getSource('rolesCLNC'), []);
                            setValue(getSource('dateOfStartingCLNC'), null);
                            setValue(getSource('dateOfEnding'), null);
                            setValue(getSource('subjectIds'), []);
                            setSelectedItems([]);
                            const emptySchedule = Object.keys(DaysOfWeek).reduce((acc, dayKey) => {
                                acc[DaysOfWeek[dayKey as keyof typeof DaysOfWeek]] = [];
                                return acc;
                            }, {} as any);
                            setValue(getSource('possibleWorkSchedule'), emptySchedule);
                        };

                        const handleStaffMemberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                            const isChecked = event.target.checked;
                            setValue(getSource('staffMember'), isChecked);
                            if (isChecked) {
                                setValue(getSource('civilLawNatureContract'), false);
                                clearCivilLawFields();
                            }
                        };

                        const handleCivilLawContractChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                            const isChecked = event.target.checked;
                            setValue(getSource('civilLawNatureContract'), isChecked);
                            if (isChecked) {
                                setValue(getSource('staffMember'), false);
                                clearStaffMemberFields();
                            }
                        };

                        useEffect(() => {
                            if (!isStaffMember) {
                                clearStaffMemberFields();
                            } else {
                                setValue(getSource('possibleWorkSchedule'), {});
                            }
                        }, [isStaffMember]);

                        useEffect(() => {
                            if (!isCivilLawContract) {
                                clearCivilLawFields();
                            }
                        }, [isCivilLawContract]);

                        return (
                            <Grid container columnSpacing={4} rowSpacing={1} mt={2}>
                                <Grid item xs={12}>
                                    <ShsAutoCompleteWithLoad
                                        source={getSource('educationCenterId')}
                                        required
                                        pageUrl="centers"
                                        filters={{ active: true }}
                                        validate={required('Вкажіть освітній центр')}
                                        keyField="id"
                                        textField="shortName"
                                        resultLimit={100}
                                        label="Освітній центр"
                                        onChange={handleEducationCenterChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DisabledWrapper isDisabled={!isEducationCenterSelected}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.12)' }}>
                                                <ShsCheckbox
                                                    source={getSource('staffMember')}
                                                    label="Штатний працівник"
                                                    defaultValue={false}
                                                    onChange={handleStaffMemberChange}
                                                    disabled={isCivilLawContract}
                                                />
                                                {isStaffMember ? (
                                                    <>
                                                        <Grid container columnSpacing={4} rowSpacing={1} mt={1}>
                                                            <Grid item xs={6}>
                                                                <ShsSelectArrayInput
                                                                    source={getSource('roles')}
                                                                    label="Роль"
                                                                    required
                                                                    validate={minArrayLength(
                                                                        1,
                                                                        "Обов'язково для заповнення"
                                                                    )}
                                                                    choices={Object.entries(UserRoleLabel)
                                                                        .filter(([id]) =>
                                                                            FULL_TIME_EMPLOYEES.includes(id as UserRole)
                                                                        )
                                                                        .map(([id, name]) => ({ id, name }))}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container columnSpacing={4} rowSpacing={1} mt={1}>
                                                            <Grid item xs={6}>
                                                                <ShsDatePicker
                                                                    source={getSource('dateOfStarting')}
                                                                    required
                                                                    label="Дата початку трудових відносин"
                                                                    removePaddingTop
                                                                    labelPosition="top"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <ShsSelectInput
                                                                    source={getSource('rate')}
                                                                    label="Розмір ставки"
                                                                    placeholder="Виберіть розмір ставки"
                                                                    required
                                                                    disabled={roles.length === 0}
                                                                    choices={Object.entries(StaffRateLabel)
                                                                        .filter(
                                                                            ([id]) =>
                                                                                !FULL_TIME_EMPLOYEES.includes(
                                                                                    id as UserRole
                                                                                )
                                                                        )
                                                                        .map(([id, name]) => ({ id, name }))}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} paddingBottom={'12px'}>
                                                            Навантаження працівника:{' '}
                                                            <b>
                                                                {StaffRateHours[rate as StaffRate] || '-'} год/тиждень
                                                            </b>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={12} sx={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.12)' }}>
                                                <ShsCheckbox
                                                    source={getSource('civilLawNatureContract')}
                                                    label="Договір цивільно-правового характеру"
                                                    defaultValue={false}
                                                    onChange={handleCivilLawContractChange}
                                                    disabled={isStaffMember}
                                                />
                                                {isCivilLawContract ? (
                                                    <>
                                                        <Grid
                                                            container
                                                            columnSpacing={4}
                                                            rowSpacing={1}
                                                            mt={1}
                                                            pb={'12px'}>
                                                            <Grid item xs={6}>
                                                                <ShsDatePicker
                                                                    source={getSource('dateOfStartingCLNC')}
                                                                    label="Дата початку трудових відносин"
                                                                    removePaddingTop
                                                                    labelPosition="top"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <ShsDatePicker
                                                                    label="Дата завершення трудових відносин"
                                                                    source={getSource('dateOfEnding')}
                                                                    removePaddingTop
                                                                    labelPosition="top"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <ShsSelectArrayInput
                                                                    source={getSource('rolesCLNC')}
                                                                    label="Освітня роль"
                                                                    required
                                                                    validate={minArrayLength(
                                                                        1,
                                                                        "Обов'язково для заповнення"
                                                                    )}
                                                                    choices={Object.entries(UserRoleLabel)
                                                                        .filter(([id]) =>
                                                                            CIVIL_LAW_NATURE_EMPLOYEES.includes(
                                                                                id as UserRole
                                                                            )
                                                                        )
                                                                        .map(([id, name]) => ({ id, name }))}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <AutoCompleteSubject
                                                                    required={isCivilLawContract ? true : false}
                                                                    setSelectedData={setSelectedItems}
                                                                    groupBy={groupByCategory}
                                                                    sort={sortOptions}
                                                                    source={getSource('subjectIds')}
                                                                    validate={minLength(
                                                                        1,
                                                                        'Хоча б одна освітня послуга повинна бути вказана'
                                                                    )}
                                                                    keyField="specialId"
                                                                    textField="name"
                                                                    resultLimit={100}
                                                                    filterChoices={filterChoices}
                                                                    label="Освітня послуга"
                                                                />
                                                            </Grid>

                                                            <Divider variant="middle" sx={{ width: '100%' }} />

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                gap={'8px'}
                                                                display={'flex'}
                                                                flexWrap={'wrap'}
                                                                margin={'10px'}>
                                                                {selectedItems?.map((item: any) => (
                                                                    <ChipLabel
                                                                        key={item.specialId + '_key'}
                                                                        label={item.name}
                                                                        onDelete={() =>
                                                                            handleRemoveService(
                                                                                getSource('subjectIds'),
                                                                                item.specialId
                                                                            )
                                                                        }
                                                                    />
                                                                ))}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    component="span"
                                                                    mb={1}
                                                                    fontWeight="400"
                                                                    color={Theme.palette.primary.optional}>
                                                                    Можливий графік роботи
                                                                </Typography>
                                                                {Object.keys(DaysOfWeek).map((dayKey, index) => {
                                                                    const day =
                                                                        DaysOfWeek[dayKey as keyof typeof DaysOfWeek];
                                                                    return (
                                                                        <Grid item xs={12} key={index}>
                                                                            <DaySchedule
                                                                                label={DaysOfWeekLabel[day]}
                                                                                getSource={(source) =>
                                                                                    getSource(
                                                                                        `possibleWorkSchedule.${day}.${source}`
                                                                                    )
                                                                                }
                                                                                isChecked={
                                                                                    !!schedule[day]?.[0]?.from &&
                                                                                    !!schedule[day]?.[0]?.to
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </DisabledWrapper>
                                </Grid>
                            </Grid>
                        );
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default EmploymentTypeForm;
