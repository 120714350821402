import { Grid, SxProps, Typography } from '@mui/material';

import { ShsTextInput } from 'src/components/common';
import { inputLabelFontStyle } from 'src/styles';

export const LinkInput: React.FC<{
    source: string;
    label: string;
    required?: boolean;
    validate?: any;
    sx?: SxProps;
}> = ({ source, label, required, validate, sx }) => {
    return (
        <Grid container sx={sx}>
            <Grid item sm={6} alignItems="center" display="flex" height="45px">
                <Typography sx={{ ...inputLabelFontStyle, paddingRight: '10px' }}>
                    {label + (required && label ? '*' : '')}
                </Typography>
            </Grid>
            <Grid item sm={6}>
                <ShsTextInput
                    name={source}
                    source={source}
                    label=""
                    required={required}
                    validate={validate}
                    fullWidth
                    placeholder={source === 'photo' ? 'Вкажіть лінк на фото' : 'Вкажіть лінк на файл'}
                    sx={{ width: '100%' }}
                    inputProps={{
                        sx: { textOverflow: 'ellipsis' }
                    }}
                />
            </Grid>
        </Grid>
    );
};
