export { shsToolbarStyles } from './toolbars';

export const shsFormErrorStyles = {
    '& .MuiFormHelperText-root.Mui-error': {
        color: '#d32f2f',
        fontSize: '0.75rem',
        padding: 0,
        margin: '-5px 14px 0 2px'
    }
};

export const disableFirstRemoveStyle = {
    '& li.RaSimpleFormIterator-line:only-child .button-remove': {
        display: 'none !important'
    },
    '& .RaSimpleFormIterator-line': {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12) !important'
    }
};

export const formIteratorStyles = {
    '& .RaSimpleFormIterator-add': {
        width: '100%'
    }
};
