import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Custom hook for handling tabbed form navigation based on URL path
 * This hook extracts the tab index from the URL and provides a function
 * to navigate between tabs by updating the URL
 */
export const useTabbedFormNavigationEmployee = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        const pathSegments = pathname.split('/').filter(Boolean);
        const tabIndex = pathSegments.length === 2 ? 0 : Number(pathSegments[2]) || 0;
        setTabIndex(tabIndex);
    }, [pathname]);

    /**
     * Navigates to a specific tab by updating the URL path
     * @param {number} newIndex - index of the tab to navigate to
     */
    const navigateToTab = (newIndex: number) => {
        const pathFragments = pathname.split('/');
        if (tabIndex > 0) {
            pathFragments.pop();
        }
        if (newIndex > 0) {
            pathFragments.push(newIndex.toString());
        }
        const newPath = '/' + pathFragments.filter(Boolean).join('/');
        navigate(newPath);
    };

    return { tabIndex, navigateToTab };
};

/**
 * Adds a listener for event, which is triggered when the user tries to leave the page
 * The provided callback will be executed before the page is unloaded
 */
export const addBeforeUnloadListener = () => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
};
