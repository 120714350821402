import React, { useState, useEffect } from 'react';
import { useCreatePath, List, Filter, useListContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Typography, Button, Box } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ClassIcon from '@mui/icons-material/Class';
import SchoolIcon from '@mui/icons-material/School';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ShsDatePicker, ShsTitle, ShsAutoCompleteWithLoad } from '../common';
import { listContainerStyles } from 'src/styles';
import { EducationCenterType } from '../Employees/types';
import { FILTER_EMPTY_VALUE } from 'src/constants';
import EmployeesReport from './EmployeesReport';
import { ChildrenReport } from './ChildrenReport';
import axiosApiInstance from 'src/api/axiosConfig';

interface InfoItem {
    icon: SvgIconComponent;
    title: string;
    subtitle: string;
    value: number | string;
    unit?: string;
}

interface InfoBlockProps {
    title: string;
    buttonLabel: string;
    onButtonClick: () => void;
    infoItems: InfoItem[];
    endDate: string;
}

const InfoBlock: React.FC<InfoBlockProps> = ({ title, buttonLabel, onButtonClick, infoItems, endDate }) => (
    <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" component="h2">
                {title}
            </Typography>
            <Button variant="text" color="primary" endIcon={<ArrowForwardIcon />} onClick={onButtonClick}>
                {buttonLabel}
            </Button>
        </Box>
        <Grid container spacing={2}>
            {infoItems.map((item, index) => (
                <Grid item xs={6} key={index}>
                    <Box display="flex" alignItems="center">
                        <Box
                            sx={{
                                backgroundColor: 'primary.main',
                                borderRadius: 2,
                                color: 'white',
                                p: 1,
                                mr: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 48,
                                height: 48
                            }}>
                            <item.icon sx={{ fontSize: 24 }} />
                        </Box>
                        <Box>
                            <Typography variant="body2" color="textSecondary">
                                {item.title}
                            </Typography>
                            <Typography variant="caption" color="textSecondary" display="block">
                                {item.subtitle.replace('()', `(${endDate})`)}
                            </Typography>
                            <Typography variant="h5" component="div" fontWeight="bold">
                                {item.value} {item.unit}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
    </Paper>
);

const ReportContent: React.FC = () => {
    const { filterValues } = useListContext();
    const [isMounted, setIsMounted] = useState(false);
    const [isReadyToFetch, setIsReadyToFetch] = useState(false);
    const navigate = useNavigate();
    const createPath = useCreatePath();
    const [childrenData, setChildrenData] = useState({ childrenCountDuringPeriod: 0, childrenCountOnLastDate: 0 });
    const [employeeData, setEmployeeData] = useState({ employeeCountDuringPeriod: 0, serviceHoursProvided: 0 });
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    useEffect(() => {
        if (isMounted && filterValues.startDate && filterValues.endDate) {
            setIsReadyToFetch(true);
        } else {
            setIsReadyToFetch(false);
        }
    }, [isMounted, filterValues.startDate, filterValues.endDate]);

    useEffect(() => {
        const fetchStatistics = async () => {
            if (!isReadyToFetch) return;

            let { educationCenterId, startDate, endDate } = filterValues;
            educationCenterId =
                educationCenterId === FILTER_EMPTY_VALUE || educationCenterId == null ? '' : educationCenterId;

            try {
                const response = await axiosApiInstance.get(
                    `reports/statistics?educationCenterId=${educationCenterId}&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`
                );
                setChildrenData({
                    childrenCountDuringPeriod: response.data.childrenCountDuringPeriod,
                    childrenCountOnLastDate: response.data.childrenCountOnLastDate
                });
                setEmployeeData({
                    employeeCountDuringPeriod: response.data.employeeCountDuringPeriod,
                    serviceHoursProvided: response.data.serviceHoursProvided
                });
            } catch (error) {
                console.error('Error fetching children counts:', error);
            }
        };

        fetchStatistics();
    }, [filterValues, isReadyToFetch]);

    const handleEmployeesReportClick = () => {
        const path = createPath({ resource: 'reports', type: 'employees' });
        navigate(path);
    };

    const handleChildrenReportClick = () => {
        const path = createPath({ resource: 'reports', type: 'children' });
        navigate(path);
    };

    const endDate = filterValues.endDate ? new Date(filterValues.endDate).toLocaleDateString('uk-UA') : '';

    const childrenInfo: InfoItem[] = [
        {
            icon: PeopleIcon,
            title: 'Кількість дітей',
            subtitle: 'за обраний період',
            value: childrenData.childrenCountDuringPeriod
        },
        {
            icon: PeopleOutlineIcon,
            title: 'Кількість дітей',
            subtitle: 'на останню дату ()',
            value: childrenData.childrenCountOnLastDate
        }
    ];

    const employeesInfo: InfoItem[] = [
        {
            icon: SchoolIcon,
            title: 'Кількість працівників',
            subtitle: 'за обраний період',
            value: employeeData.employeeCountDuringPeriod
        },
        {
            icon: ClassIcon,
            title: 'Надані послуги',
            subtitle: 'за обраний період',
            value: employeeData.serviceHoursProvided,
            unit: 'год'
        }
    ];

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <InfoBlock
                        title="Інформація про дітей"
                        buttonLabel="Список дітей"
                        onButtonClick={handleChildrenReportClick}
                        infoItems={childrenInfo}
                        endDate={endDate}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InfoBlock
                        title="Інформація про працівників"
                        buttonLabel="Список працівників"
                        onButtonClick={handleEmployeesReportClick}
                        infoItems={employeesInfo}
                        endDate={endDate}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export const Report: React.FC = () => {
    const [currentView, setCurrentView] = useState<'main' | 'employees' | 'children'>('main');
    const navigate = useNavigate();
    const createPath = useCreatePath();

    useEffect(() => {
        if (location.pathname.endsWith('/employees')) {
            setCurrentView('employees');
        } else if (location.pathname.endsWith('/children')) {
            setCurrentView('children');
        } else {
            setCurrentView('main');
        }
    }, [location.pathname]);

    const handleReturn = () => {
        const path = createPath({ resource: 'reports', type: 'list' });
        navigate(path);
    };

    if (currentView === 'employees') {
        return <EmployeesReport onReturn={handleReturn} />;
    }

    if (currentView === 'children') {
        return <ChildrenReport onReturn={handleReturn} />;
    }

    return (
        <Container sx={listContainerStyles}>
            <ShsTitle title="Звіти" />
            <List<EducationCenterType>
                resource="centers"
                exporter={false}
                empty={false}
                actions={false}
                filters={
                    <Filter>
                        <ShsAutoCompleteWithLoad
                            source="educationCenterId"
                            pageUrl="centers"
                            filters={{ isActive: true }}
                            emptyValue={FILTER_EMPTY_VALUE}
                            emptyText="Усі"
                            alwaysOn
                            keyField="id"
                            textField="shortName"
                            resultLimit={100}
                            label="Освітній центр"
                        />
                        <ShsDatePicker source={'startDate'} label="Початок періоду" alwaysOn required />
                        <ShsDatePicker source={'endDate'} label="Кінець періоду" alwaysOn required />
                    </Filter>
                }
                pagination={false}>
                <ReportContent />
            </List>
        </Container>
    );
};
