/**
 * Navigates to the first invalid field in the form, based on validation results
 * Сhecks each tab group for invalid fields and scrolls to the first one it finds
 * If the field is invalid, it sets the field value and marks it as touched
 */
export const goToFirstInvalidField = async (
    tabGroups: string[],
    getGroupFields: (tab: string) => string[],
    trigger: any,
    getValues: any,
    setValue: any,
    navigateToTab: (index: number) => void
) => {
    for (let i = 0; i < tabGroups.length; i++) {
        const tabFields = getGroupFields(tabGroups[i]);
        const validationResults = await Promise.all(tabFields.map((field) => trigger(field)));
        const firstInvalidIndex = validationResults.findIndex((isValid) => !isValid);

        if (firstInvalidIndex !== -1) {
            const firstErrorField = tabFields[firstInvalidIndex];

            setValue(firstErrorField, getValues(firstErrorField), { shouldTouch: true });

            navigateToTab(i);

            const el = document.getElementById(firstErrorField);
            if (el) {
                setTimeout(() => {
                    el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }, 200);
            }
            return;
        }
    }
};
