import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { TextInputProps, useInput } from 'react-admin';

interface ShsCheckboxProps extends TextInputProps {
    label?: string;
}

const ShsCheckbox: React.FC<ShsCheckboxProps> = ({
    label,
    source,
    onChange,
    defaultValue = false,
    sx = {},
    disabled
}) => {
    const [checked, setChecked] = React.useState(defaultValue);
    const { field } = useInput({ source, defaultValue });

    React.useEffect(() => {
        setChecked(Boolean(field.value) || false);
    }, [field.value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setChecked(isChecked);
        field.onChange(isChecked);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...field}
                    checked={checked}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleChange}
                    disabled={disabled}
                />
            }
            label={label}
            sx={sx}
        />
    );
};

export default ShsCheckbox;
