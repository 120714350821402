import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Toolbar, useCreatePath, useDelete, useNotify, useRecordContext, useUpdate, useFormGroups } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { shsButtonStyles } from 'src/styles';
import { ShsButton, WithRole } from 'src/components/common';
import { ReactComponent as DeleteIcon } from 'src/assets/trash-can.svg';
import { Resource } from 'src/constants';
import { UserRole } from 'src/auth/types';
import ShsFormToolbar from 'src/components/common/ShsFormToolbar';
import { addBeforeUnloadListener, useTabbedFormNavigationEmployee } from 'src/utils/employees/navigation';
import { goToFirstInvalidField } from 'src/utils/employees/formValidation';

import { DeleteModalEmployee } from '../modals';
import tabGroups from '../const/tab-groups';

export type ModalProps = {
    isOpen: boolean;
    onConfirm?: () => void;
    onClose: () => void;
};

const shsToolbarStyles = {
    '&.MuiToolbar-root': {
        '&.RaToolbar-desktopToolbar': {
            marginTop: 0
        },
        background: 'transparent',
        padding: 0,
        width: '100%'
    }
};

export interface ShsFormToolbarEmployeeProps {
    submitLabel?: string;
    enableCancelButton?: boolean;
    deleteLabel?: string;
    editMode?: boolean;
    handleCancelModalFromParent?: () => void;
    handleCancel?: () => void;
    onCancel?: () => void;
    onSuccess?: () => void;
}

const EmployeeEditToolbar: React.FC<ShsFormToolbarEmployeeProps> = ({
    editMode = false,
    deleteLabel = '',
    submitLabel = '',
    enableCancelButton = false,
    onCancel = () => {},
    handleCancelModalFromParent = () => {},
    onSuccess = () => {}
}) => {
    const { trigger, getValues, handleSubmit, setValue } = useFormContext();
    const [deleteOne] = useDelete();
    const [update] = useUpdate();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const notify = useNotify();
    const createPath = useCreatePath();
    const record = useRecordContext();
    const navigate = useNavigate();
    const { navigateToTab } = useTabbedFormNavigationEmployee();
    const { getGroupFields } = useFormGroups();

    /**
     * Closes the delete confirmation modal
     */
    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    /**
     * Opens the delete confirmation modal
     */
    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    /**
     * Handles the save button click for a modal with cancel functionality
     * - If invalid, navigates to the first invalid field
     * - If valid, submits the form and updates the employee data
     */
    const handleSaveBtnClickCancelModal = async () => {
        const isValid = await trigger();

        if (!isValid) {
            goToFirstInvalidField(tabGroups, getGroupFields, trigger, getValues, setValue, navigateToTab);
            return;
        }

        await handleSubmit((data) => {
            update(
                Resource.EMPLOYEES,
                {
                    id: data.id,
                    data: { ...data },
                    meta: { useMock: false, method: `update` }
                },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: () => {
                        onSuccess();
                        onCancel();
                    },
                    onError: (error: any) => {
                        onCancel();
                        notify(`Помилка: ${error?.message}`, {
                            type: 'error'
                        });
                    }
                }
            );
        })();
    };

    /**
     * Show default confirm reload modal on page reload
     */
    useEffect(() => {
        const removeBeforeUnloadListener = addBeforeUnloadListener();
        return () => {
            removeBeforeUnloadListener();
        };
    }, []);

    /**
     * Handles the click event for the delete button
     */
    const handleDeleteBtnClick = () => {
        deleteOne(
            Resource.EMPLOYEES,
            { id: record.id },
            {
                mutationMode: 'pessimistic',
                onSuccess: () => {
                    onSuccess();
                    navigate(createPath({ resource: 'employees', type: 'list' }));
                },
                onError: (error: any) => {
                    notify(`Помилка: ${error?.message}`, {
                        type: 'error'
                    });
                }
            }
        );
        setDeleteModalOpen(false);
    };

    return (
        <Toolbar sx={shsToolbarStyles}>
            <ShsFormToolbar
                submitLabel={submitLabel}
                enableSaveButton={true}
                handleCancel={handleCancelModalFromParent}
                sx={shsButtonStyles}
                enableCancelButton={enableCancelButton}
                shouldCheckIfDirty={false}
                handleSave={handleSaveBtnClickCancelModal}
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexGrow: 1 }}>
                {editMode && (
                    <WithRole roles={[UserRole.SYSTEM_ADMIN]}>
                        <ShsButton
                            label={deleteLabel}
                            sx={{
                                marginRight: 0,
                                whiteSpace: 'nowrap',
                                padding: '0.75em 1.75em'
                            }}
                            styleType="outline-danger"
                            icon={<DeleteIcon style={{ fill: '#F23030' }} />}
                            onClick={handleOpenDeleteModal}
                        />
                    </WithRole>
                )}
            </Box>

            <DeleteModalEmployee
                isOpen={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                onConfirm={handleDeleteBtnClick}
            />
        </Toolbar>
    );
};

export default EmployeeEditToolbar;
