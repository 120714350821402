import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Grid, Box, Divider } from '@mui/material';

import { formatPhoneNumber } from 'src/utils';
import { sectionTitleStyle, smallGrayFontStyle } from 'src/styles';
import { ChildFormFields } from 'src/constants';

export const VisitorData: React.FC = () => {
    const record = useRecordContext();
    const visitors = record?.[ChildFormFields.VISIT][ChildFormFields.VISITORS];
    return (
        <>
            <Grid item xs={12}>
                <Box sx={sectionTitleStyle}>Законний представник дитини</Box>
            </Grid>
            <>
                {visitors
                    .filter((visitor: any) => visitor.active)
                    .map(({ parent }: any) => (
                        <React.Fragment key={parent[ChildFormFields.ID]}>
                            <Grid item xs={6}>
                                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                                    Прізвище ім&apos;я по-батькові:
                                </Box>
                                {`${parent[ChildFormFields.VISITOR_FIRST_NAME]} ${
                                    parent[ChildFormFields.VISITOR_PATRONYMIC]
                                } ${parent[ChildFormFields.VISITOR_LAST_NAME]}`}
                            </Grid>
                            <Grid item xs={6} key={parent.taxNumber}>
                                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                                    Ідентифікаційний номер платника податків (РНОКПП):
                                </Box>
                                {parent[ChildFormFields.VISITOR_TAX_NUMBER]}
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                                    Контактний номер телефону
                                </Box>
                                {formatPhoneNumber(parent[ChildFormFields.VISITOR_PHONE])}
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={smallGrayFontStyle} mt={1.5} mb={0.3}>
                                    Email
                                </Box>
                                {parent[ChildFormFields.VISITOR_EMAIL]}
                            </Grid>
                        </React.Fragment>
                    ))}
            </>
            <Grid item xs={12}>
                <Divider sx={{ mb: 2, mt: 2 }} />
            </Grid>
        </>
    );
};

export default VisitorData;
