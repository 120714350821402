import { FC } from 'react';
import { Card } from '@mui/material';

import { ShsTextInput, ShsCheckboxGroup } from 'src/components/common';
import { TeachersList } from 'src/constants/centers/form';
import { required } from 'react-admin';

export const SpecialServiceInputsCard: FC = () => {
    return (
        <Card sx={{ width: '100%', marginBottom: 2, padding: 2 }}>
            <ShsTextInput source={'name'} label="Назва послуги" validate={required()} />
            <ShsCheckboxGroup
                source={'roles'}
                label="Спеціальний викладач"
                choices={TeachersList}
                validate={required('Щоб зберегти послугу виберіть хоча б одного спеціального викладача')}
            />
        </Card>
    );
};
