import React from 'react';
import { Confirm, useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';

import { shsModalsStyles, interFontStyle } from 'src/styles';
import CircleWarning from 'src/assets/CircleWarning';
import { ShsButton } from 'src/components/common';

const modalsStyles = {
    ...shsModalsStyles,
    '& .MuiPaper-root': {
        maxWidth: '540px',
        borderRadius: '20px'
    },
    '& .MuiDialogContent-root': {
        padding: 0,
        '> .shs-modal-body': {
            padding: '0 63px'
        }
    },
    '& .shs-modal-footer': {
        padding: '20px 50px 50px',
        '> .MuiButton-root': {
            minWidth: '235px',
            padding: '13px 10px',
            '&.outline': {
                color: '#111928',
                border: '1px solid #DFE4EA'
            }
        }
    }
};

export const DeleteModalEmployee: React.FC<any> = ({ isOpen, onClose, onConfirm }) => {
    const record = useRecordContext();
    const name = `${record.name} ${record.surname} ${record.patronymic}`.trim();

    return (
        <>
            <Confirm
                isOpen={isOpen}
                loading={false}
                title={''}
                onConfirm={close}
                onClose={close}
                sx={modalsStyles}
                content={
                    <>
                        <div className="shs-modal-header">
                            <Box sx={{ textAlign: 'center' }}>
                                <CircleWarning />
                            </Box>
                            <Typography variant="h5" component="h5" mb={2} mt={1} fontWeight="600" align="center">
                                Видалення працівника
                            </Typography>
                        </div>
                        <div className="shs-modal-body">
                            <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                                Чи впевнені ви що хочете видалити працівника "{name}"?
                            </Typography>
                            <Typography component="div" mb={4} align="center" sx={interFontStyle}>
                                Після видалення працівника він буде видалений у всіх розкладах та будуть втрачені всі
                                його дані про надані послуги дітям.
                            </Typography>
                        </div>
                        <div className="shs-modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ShsButton
                                label="Скасувати"
                                styleType="outline"
                                onClick={onClose}
                                sx={{ flex: '1', marginRight: '1em' }}
                            />
                            <ShsButton
                                label="Видалити"
                                styleType="filled-danger"
                                onClick={onConfirm}
                                sx={{ flex: '1' }}
                            />
                        </div>
                    </>
                }
            />
        </>
    );
};
