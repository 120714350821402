import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { interFontStyle } from 'src/styles';
import CircleWarning from 'src/assets/CircleWarning';

/**
 * Props for the ValidationErrorModal component
 * @property { boolean } isOpen - Indicates if the modal is open or not
 * @property { () => void } onClose - Function to call when closing the modal
 */
interface ValidationErrorModalProps {
    isOpen: boolean;
    onClose: () => void;
}

/**
 * A modal component that displays a validation error message
 * It prompts the user to fill in required fields to save data
 */
export const ValidationErrorModal: React.FC<ValidationErrorModalProps> = ({ isOpen, onClose }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '530px',
                    borderRadius: '20px',
                    p: 2
                }
            }}>
            <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div className="shs-modal-header">
                    <Box sx={{ textAlign: 'center' }}>
                        <CircleWarning />
                    </Box>
                    <Typography variant="h5" component="h5" mb={2} mt={1} fontWeight="600" align="center">
                        Зберегти дані
                    </Typography>
                </div>
                <div className="shs-modal-body">
                    <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                        Для збереження даних заповніть обов'язкові поля
                    </Typography>
                </div>
            </DialogContent>
        </Dialog>
    );
};
