import axios from 'axios';
import { HttpError } from 'react-admin';

import type { AuthUserType } from '../auth/types';
import { getAPIFormErrors } from './helpers';
import { clearAuthInLocalStorage, getAuthFromLocalStorage, updateAuthInLocalStorage } from '../auth/authProvider';
import { setupCache } from 'axios-cache-interceptor';

const axiosApiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_JOURNAL_SERVICE || ''}${process.env.REACT_APP_API_BASE_PATH || ''}`
});
setupCache(axiosApiInstance);

export function initAxios() {
    axiosApiInstance.interceptors.request.use(
        async (config) => {
            const authUser: AuthUserType = getAuthFromLocalStorage();
            if (!authUser) {
                return config;
            }

            config.headers = {
                Authorization: `Bearer ${authUser.access_token}`,
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                ...(config.headers || {})
            } as any;
            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosApiInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            const {
                config: originalRequest,
                response: { status, data }
            } = error;
            const authUser: AuthUserType = getAuthFromLocalStorage();
            if (authUser && status === 401 && !originalRequest._retry && originalRequest.url != '/auth/refresh') {
                originalRequest._retry = true;

                const newAccessToken = await refreshAccessToken(authUser.refresh_token);
                originalRequest.headers = {
                    ...(originalRequest.headers || {}),
                    Authorization: `Bearer ${newAccessToken}`
                };
                return axiosApiInstance(originalRequest);
            }

            if (authUser && status === 401) {
                if (data.message === 'Користувач має оновити медичні дані') {
                    window.location.href = '/restricted';
                    return Promise.resolve();
                } else {
                    clearAuthInLocalStorage();
                }
            }

            if (status === 400) {
                return Promise.reject(
                    new HttpError('Запит відхилено. Перевірте помилки.', status, {
                        errors: getAPIFormErrors(data.errors)
                    })
                );
            }

            return Promise.reject(error);
        }
    );
}

function refreshAccessToken(refreshToken: string): Promise<string> {
    return axiosApiInstance
        .get('/auth/refresh', { headers: { Authorization: `Bearer ${refreshToken}` } })
        .then((response) => {
            updateAuthInLocalStorage(response.data);
            return response.data.access_token;
        });
}

export const postRequestCacheConfig: any = { cache: { methods: ['post'], interpretHeader: false } };

export default axiosApiInstance;
