import { AppBar, useAuthProvider, useNotify, UserMenu as UserMenuReactAdmin } from 'react-admin';
import UserMenu from 'src/components/UserMenu';
import { palette, Theme } from '../../../Theme';
import './index.css';
import { ReactComponent as OsvCentr } from 'src/assets/osv-centr.svg';
import { ReactComponent as ChevronDown } from 'src/assets/chevron-down.svg';
import { WithRole } from 'src/components/common';
import { SpecialTeachersGroup, TeachersGroup } from 'src/constants/roles';
import { useEffect, useState } from 'react';
import { ReactComponent as Ring } from 'src/assets/ring.svg';
import { Badge, Box, IconButton, Popover, Typography } from '@mui/material';
import axiosApiInstance from 'src/api/axiosConfig';
import { UserRole } from 'src/auth/types';
import { useNotification } from 'src/utils/notification';
import ShsAlert from 'src/components/common/ShsAlert';
import { NotificationResponse } from 'src/components/common/ShsAlert/constants';

const HeaderStyles = {
    boxShadow: 'none',
    zIndex: 9,
    '& .RaAppBar-toolbar': {
        background: '#FFFFFF',
        borderBottom: `1px solid ${palette.primary.inputBorder}`,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    '& .RaAppBar-toolbar > .RaAppBar-menuButton': { display: 'none' },
    '& .RaAppBar-toolbar > .RaAppBar-title > span.RaConfigurable-root': { display: 'none' }
};

type EduCenter = {
    id: number;
    name: string;
};

export const Header = () => {
    const { changeCenter, getEducationCenterId } = useAuthProvider();
    const notify = useNotify();
    const notification = useNotification();

    const [anchorEl, setAnchorEl] = useState<any | null>(null);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [checkedNotification, setCheckedNotification] = useState<any[]>([]);
    const [notificationCount, setNotificationCount] = useState<number>(
        Number(notification.getNotificationLengthFromLocalStorage()) || 0
    );

    const handleClick = (event: any) => {
        const data = notification.getNotification();
        const checked = notification.getLocalNotification();
        setNotifications(data.filter((x: any) => !checked.find((a: number) => a === x.employeeId)));
        setCheckedNotification(data.filter((x: any) => checked.find((a: number) => a === x.employeeId)));
        setNotificationCount(Number(notification.getNotificationLengthFromLocalStorage()) || 0);
        setAnchorEl(event.currentTarget);
    };

    const handleClickReadMarker = (id: number) => {
        notification.changeNotificationReadStatus(id);
        setNotificationCount(Number(notification.getNotificationLengthFromLocalStorage()) || 0);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [availableEduCenters, setAvailableEduCenters] = useState<EduCenter[]>([]);
    const eduCenter = availableEduCenters.find((x) => x.id === getEducationCenterId());

    useEffect(() => {
        axiosApiInstance.get(`notifications`).then((response) => {
            localStorage.setItem('notifications', JSON.stringify(response.data));
            setNotificationCount(notification.getNotificationLengthFromLocalStorage() || response.data.length);
        });
        axiosApiInstance.get('/centers/names').then((response) => setAvailableEduCenters(response.data));
    }, []);

    const handleChangeEduCanter = (id: EduCenter['id']) => {
        changeCenter(id)
            .then(() => {
                window.location.reload();
            })
            .catch(() => {
                notify('Невдалося змінити навчальний центр', {
                    type: 'error',
                    autoHideDuration: 2500
                });
            });
    };

    return (
        <AppBar sx={HeaderStyles} container="div" userMenu={<UserMenu />}>
            {eduCenter && (
                <WithRole roles={[...TeachersGroup, ...SpecialTeachersGroup, UserRole.ADMIN, UserRole.METHODOLOGIST]}>
                    <div style={{ width: '50%' }}>
                        <div
                            style={{
                                color: 'black',
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px'
                            }}>
                            <Box
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        background: 'rgba(234, 233, 233, 0.671)'
                                    }
                                }}>
                                <OsvCentr />
                            </Box>

                            <Typography fontSize={16} fontWeight={600} color={Theme.palette.primary.midnightBlue}>
                                {eduCenter.name}
                            </Typography>
                            {availableEduCenters.length > 1 && (
                                <UserMenuReactAdmin icon={<ChevronDown />}>
                                    {availableEduCenters
                                        .filter((x) => x.id !== eduCenter.id)
                                        .map((center) => (
                                            <Box
                                                key={center.id}
                                                onClick={() => handleChangeEduCanter(center.id)}
                                                sx={{
                                                    padding: '10px 15px',
                                                    maxWidth: '700px'
                                                }}>
                                                <Typography
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    color={Theme.palette.primary.midnightBlue}>
                                                    {center.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                </UserMenuReactAdmin>
                            )}
                        </div>
                    </div>
                </WithRole>
            )}
            <IconButton size="large" color="primary" onClick={(e) => handleClick(e)}>
                <Badge badgeContent={notificationCount} color="error">
                    <Ring />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                style={{
                    borderRadius: '8px',
                    maxHeight: '90%'
                }}>
                <Box
                    style={{
                        background: '#ffffff'
                    }}>
                    <Typography
                        fontSize={24}
                        fontWeight={700}
                        color={'#000000'}
                        position={'sticky'}
                        top={0}
                        sx={{
                            backgroundColor: '#ffffff'
                        }}
                        padding={'8px 16px'}>
                        Сповіщення
                    </Typography>
                    <Box
                        style={{
                            background: '#ffffff',
                            height: '80%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px'
                        }}>
                        {notifications.length > 0 && (
                            <Typography
                                fontSize={18}
                                fontWeight={600}
                                color={'#000000'}
                                position={'sticky'}
                                top={'52px'}
                                sx={{
                                    backgroundColor: '#ffffff'
                                }}
                                padding={'0 16px'}>
                                Нові
                            </Typography>
                        )}
                        {notifications
                            .sort((a, b) => a.daysToExpiration - b.daysToExpiration)
                            .map((x: NotificationResponse) => (
                                <ShsAlert
                                    type={x.type}
                                    key={x.employeeId}
                                    name={x.name}
                                    surname={x.surname}
                                    patronymic={x.patronymic}
                                    employeeId={x.employeeId}
                                    daysToExpiration={x.daysToExpiration}
                                    medicalCheckupExpirationDate={x.medicalCheckupExpirationDate}
                                    handleClickMarker={handleClickReadMarker}
                                />
                            ))}
                        {checkedNotification.length > 0 && (
                            <Typography
                                fontSize={18}
                                fontWeight={600}
                                color={'#000000'}
                                position={'sticky'}
                                top={'52px'}
                                sx={{
                                    backgroundColor: '#ffffff'
                                }}
                                padding={'0 16px'}>
                                Переглянуті
                            </Typography>
                        )}
                        {checkedNotification
                            .sort((a, b) => a.daysToExpiration - b.daysToExpiration)
                            .map((x: NotificationResponse) => (
                                <ShsAlert
                                    type={x.type}
                                    key={x.employeeId}
                                    name={x.name}
                                    surname={x.surname}
                                    patronymic={x.patronymic}
                                    employeeId={x.employeeId}
                                    daysToExpiration={x.daysToExpiration}
                                    medicalCheckupExpirationDate={x.medicalCheckupExpirationDate}
                                    checked={true}
                                    handleClickMarker={handleClickReadMarker}
                                />
                            ))}
                        {notifications.length === 0 && (
                            <Box
                                width={'450px'}
                                padding={'20px 0'}
                                color={Theme.palette.primary.gray}
                                textAlign={'center'}>
                                Немає нових сповіщень
                            </Box>
                        )}
                    </Box>
                </Box>
            </Popover>
        </AppBar>
    );
};
