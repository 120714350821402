import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Confirm, useFormGroups, useNotify, useUpdate } from 'react-admin';
import { Typography, Box } from '@mui/material';

import { shsModalsStyles, interFontStyle } from 'src/styles';
import CircleWarning from 'src/assets/CircleWarning';
import { ShsButton } from 'src/components/common';
import { Resource } from 'src/constants';
import { useTabbedFormNavigationEmployee } from 'src/utils/employees/navigation';

import { ModalProps } from '../../common/ShsFormToolbar';
import tabGroups from '../const/tab-groups';
import { goToFirstInvalidField } from 'src/utils/employees/formValidation';

const modalsStyles = {
    ...shsModalsStyles,
    '& .MuiPaper-root': {
        maxWidth: '588px',
        borderRadius: '20px'
    },
    '& .MuiDialogContent-root': {
        padding: 0,
        '> .shs-modal-body': {
            padding: '0 73px'
        }
    },
    '& .shs-modal-header': {
        maxWidth: '425px',
        margin: '3em auto 0 auto'
    },
    '& .shs-modal-footer': {
        padding: '20px 50px 50px',
        '> .MuiButton-root': {
            minWidth: '235px',
            padding: '13px 20px',
            '&.outline': {
                color: '#111928',
                border: '1px solid #DFE4EA'
            }
        }
    }
};

const modalsEditStyles = {
    ...modalsStyles,
    '& .shs-modal-footer': {
        padding: '20px 50px 50px',
        '> .MuiButton-root': {
            minWidth: '235px',
            padding: '13px 13px',
            '&.outline': {
                color: '#111928',
                border: '1px solid #DFE4EA'
            }
        }
    }
};

type ModalPropsEmployee = ModalProps & {
    handleValidationError?: () => void;
    handleSaveBtnClick?: () => {};
    handleRedirect?: () => void;
    onSuccess?: () => void;
};

/**
 *  CancelEditEmployeeModal renders a confirmation modal when the user attempts to cancel
 *  an edit or adding of an employee entity in the form.
 */
export const CancelEditEmployeeModal: React.FC<ModalPropsEmployee> = ({
    isOpen,
    isEdit,
    onClose,
    handleRedirect = () => {},
    onSuccess = () => {}
}) => {
    const { isDirty } = useFormState();
    const { trigger, getValues, handleSubmit, setValue } = useFormContext();
    const [update] = useUpdate();
    const notify = useNotify();

    let { navigateToTab } = useTabbedFormNavigationEmployee();
    const { getGroupFields } = useFormGroups();

    /**
     * Handles save button click event in cancel modal
     * Validates form fields and performs save operation if valid
     * If form is not valid, it navigates to the first invalid field
     */
    const handleSaveBtnClickCancelModal = async () => {
        const isValid = await trigger();
        if (!isValid) {
            goToFirstInvalidField(tabGroups, getGroupFields, trigger, getValues, setValue, navigateToTab);
            onClose();
            return;
        }

        await handleSubmit((data) => {
            update(
                Resource.EMPLOYEES,
                {
                    id: data.id,
                    data: { ...data },
                    meta: { useMock: false, method: `update` }
                },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: () => {
                        handleRedirect();
                        onSuccess();
                    },
                    onError: (error: any) => {
                        handleRedirect();
                        notify(`Помилка: ${error?.message}`, {
                            type: 'error'
                        });
                    }
                }
            );
        })();
    };

    return (
        <>
            <Confirm
                isOpen={isOpen}
                loading={false}
                title={''}
                onConfirm={onClose}
                onClose={onClose}
                sx={isEdit ? modalsEditStyles : modalsStyles}
                content={
                    <>
                        <div className="shs-modal-header">
                            <Box sx={{ textAlign: 'center' }}>
                                <CircleWarning />
                            </Box>
                            <Typography variant="h5" component="h5" mb={2} mt={1} fontWeight="600" align="center">
                                {isEdit && !isDirty ? 'Скасувати редагування працівника' : 'Увага!'}
                            </Typography>
                        </div>
                        <div className="shs-modal-body">
                            <Typography component="div" mb={3} align="center" sx={interFontStyle}>
                                {isEdit
                                    ? isDirty
                                        ? 'Якщо ви повернетеся до профілю працівника, редагування працівника буде скасовано і всі введені дані будуть втрачені.'
                                        : 'Якщо ви повернетеся до профілю працівника, редагування працівника буде скасовано. Чи впевнені ви що хочете скасувати редагування працівника?'
                                    : 'Якщо ви повернетеся до списку працівників, додавання нового працівника буде скасовано і всі введені дані будуть втрачені.'}
                            </Typography>
                        </div>
                        <div className="shs-modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <ShsButton
                                label={
                                    isEdit
                                        ? isDirty
                                            ? 'Перейти до профілю'
                                            : 'Скасувати редагування'
                                        : 'Повернутися до списку'
                                }
                                styleType="outline"
                                onClick={handleRedirect}
                                sx={{ flex: '1', marginRight: '1em' }}
                            />
                            {isDirty ? (
                                <ShsButton
                                    label={isEdit ? 'Зберегти зміни' : 'Продовжити додавання'}
                                    styleType="filled-danger"
                                    onClick={handleSaveBtnClickCancelModal}
                                    sx={{ flex: '1' }}
                                />
                            ) : (
                                <ShsButton
                                    label={isEdit ? 'Продовжити редагування' : 'Продовжити додавання'}
                                    styleType="filled-danger"
                                    onClick={onClose}
                                    sx={{ flex: '1' }}
                                />
                            )}
                        </div>
                    </>
                }
            />
        </>
    );
};
