import { useNavigate } from 'react-router-dom';
import ShsButton from 'src/components/common/Fields/ShsButton';
import { ReactComponent as RestrictedLogo } from 'src/assets/restricted.svg';

import '../index.css';
import { Box, Typography } from '@mui/material';
import { clearAuthInLocalStorage } from 'src/auth/authProvider';

const Restricted = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        clearAuthInLocalStorage();
        navigate('/login');
    };

    return (
        <Box className="restricted_container" height={'100vh'}>
            <div className="restricted_logo">
                <RestrictedLogo />
            </div>
            <Box className="restricted_content" gap={2}>
                <Typography fontWeight={600} fontSize={'24px'}>
                    Доступ до профілю тимчасово обмежено
                </Typography>
                <Typography fontSize={'14px'}>
                    Термін дії вашого медичного огляду закінчився, тому ваш доступ до профілю тимчасово призупинено. Для
                    поновлення доступу завідувач має оновити вашу медичну документацію.
                    <Box paddingTop={'10px'}>
                        <Typography fontWeight={600}>Що робити далі:</Typography>
                        <ul>
                            <li>Пройдіть мед. огляд</li>
                            <li>Зверніться до свого завідувача для оновлення медичного огляду</li>
                            <li> Після оновлення медичного огляду ви зможете знову користуватися профілем</li>
                        </ul>
                    </Box>
                    Після оновлення медичних даних ваш доступ буде автоматично відновлено.
                </Typography>
                <ShsButton label="Вийти з профілю" onClick={handleClick} sx={{ maxWidth: 'fit-content' }} />
            </Box>
        </Box>
    );
};

export default Restricted;
