import React from 'react';
import { Edit, SimpleForm, useCreatePath } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from '../../common';
import { palette } from '../../../Theme';
import { SpecialServiceInputsCard } from '../common/SpecialServiceInputsCard';
import { Resource } from 'src/constants';
import { EditSpecialServiceActionToolbar } from './EditSpecialServiceActionToolbar';

export const EditSpecialService: React.FC = () => {
    const createPath = useCreatePath();

    const transform = (data: any) => ({
        ...data,
        name: data.name.trim()
    });

    return (
        <Container>
            <ShsReturnButton
                to={createPath({ resource: Resource.SPECIAL_SERVICES, type: 'list' })}
                label="Назад до спеціальних послуг"
                sx={{ color: palette.primary.grayBluish }}
            />
            <Typography fontSize={40} fontWeight={700} sx={{ mb: 3 }}>
                Редагувати послугу
            </Typography>
            <Edit transform={transform} sx={{ '.RaEdit-card': { backgroundColor: 'transparent', boxShadow: 'none' } }}>
                <SimpleForm toolbar={false} mode="onBlur" sx={{ padding: 0 }}>
                    <SpecialServiceInputsCard />
                    <EditSpecialServiceActionToolbar />
                </SimpleForm>
            </Edit>
        </Container>
    );
};
