import React, { useEffect, useState } from 'react';
import { FormDataConsumer, CheckboxGroupInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
    Typography,
    Grid,
    Box,
    Divider,
    Checkbox,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { Theme } from 'src/Theme';
import {
    ChildFormFields,
    EducationLevelList,
    YesNoChoices,
    SpecialEducationRequirementsList,
    DisabilityGroupList,
    DisabilityReasonList,
    SocialStatusLabels
} from 'src/constants';
import {
    ShsTextInput,
    ShsRadioButtonGroup,
    ShsDatePicker,
    ShsSelectInput,
    ShsCheckbox,
    ShsAutoCompleteWithLoad
} from 'src/components/common';

import { get } from 'lodash';
import { childrenUtils } from 'src/utils';
import { ChildVisit, VisitParents } from '../../types';

const getEmptyVisit = (defaultValues: ChildVisit): ChildVisit => {
    return {
        ...defaultValues,
        educationLevel: null,
        servicesAgreementDate: '',
        servicesEndDate: null,
        educationCenter: null,
        educationCenterId: null,
        hospitalDepartment: null,
        hospitalDepartmentId: null,
        info: null,
        mediaRecordingAgreement: false,
        disabilityPresent: false,
        // nulled values below - says to the backend that it suppose to be a new education
        id: null,
        version: null,
        visitParents: defaultValues.visitParents.map((visitParent: VisitParents) => {
            return { ...visitParent, id: null, version: null };
        })
    };
};

const labelStyles = {
    '& .text_field__label': {
        color: Theme.palette.primary.optional
    }
};

interface EducationalGridAdditional {
    isReadOnly?: boolean;
    i?: number;
}

export const EducationChildData: React.FC = () => {
    const [isOtherSocialStatus, setIsOtherSocialStatus] = useState(false);
    const [educationCenterId, setEducationCenterId] = useState<undefined | number>(undefined);

    const formContext = useFormContext();
    const { defaultValues } = formContext.formState;

    const isInactive = childrenUtils.isChildEducationInactive(get(defaultValues, 'visit.servicesEndDate'));

    useEffect(() => {
        if (!isInactive) {
            setIsOtherSocialStatus(Boolean(defaultValues?.visit?.otherSocialStatus));
            setEducationCenterId(defaultValues?.visit?.hospitalDepartmentId || 0);
        } else {
            formContext.setValue('visit', getEmptyVisit(defaultValues?.visit));
        }
    }, [defaultValues]);

    let visitHistory: ChildVisit[] = defaultValues?.visitHistory || [];
    if (visitHistory.length && !isInactive) {
        const currentVisitId = defaultValues?.visit?.id;
        visitHistory = visitHistory.filter((visit) => visit.id !== currentVisitId);
    }

    const EducationalGrid = (additionalProperties: EducationalGridAdditional) => {
        const isShowHistoryItem = additionalProperties.isReadOnly && typeof additionalProperties.i === 'number';
        const getSource = (visitPropertyName: ChildFormFields) => {
            return isShowHistoryItem
                ? `${ChildFormFields.VISIT_HISTORY}[${additionalProperties.i}].${visitPropertyName}`
                : `${ChildFormFields.VISIT}.${visitPropertyName}`;
        };
        const historyItemInfo = isShowHistoryItem && defaultValues!['visitHistory'][additionalProperties.i!]?.info;
        const extractValue = (targetObject: any, nestedPath: string, defaultValue = ''): any =>
            nestedPath
                .split('.')
                .reduce(
                    (current: any, key: string) => (current && current[key] !== undefined ? current[key] : undefined),
                    targetObject
                ) ?? defaultValue;

        return (
            <Grid container columnSpacing={4} rowSpacing={1} marginRight={20}>
                <Grid item xs={6}>
                    <ShsSelectInput
                        source={getSource(ChildFormFields.EDUCATIONAL_LEVEL)}
                        label="Рівень освіти"
                        choices={EducationLevelList}
                        required={!additionalProperties.isReadOnly}
                        readOnly={additionalProperties.isReadOnly}
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={6}>
                    <ShsAutoCompleteWithLoad
                        source={getSource(ChildFormFields.EDUCATION_CENTER)}
                        pageUrl="centers"
                        // emptyText=""
                        keyField="id"
                        textField="shortName"
                        resultLimit={100}
                        label="Освітній центр"
                        filters={{ active: true }}
                        onChange={(id: number) => {
                            formContext.setValue('visit.hospitalDepartmentId', null);
                            setEducationCenterId(id);
                            formContext.trigger('visit.hospitalDepartmentId');
                        }}
                        required={!additionalProperties.isReadOnly}
                        readOnly={additionalProperties.isReadOnly}
                    />
                </Grid>
                <Grid item xs={6} sx={educationCenterId ? {} : labelStyles}>
                    <ShsAutoCompleteWithLoad
                        source={getSource(ChildFormFields.HOSPITAL_DEPARTMENT)}
                        pageUrl="hospital-departments"
                        keyField="id"
                        textField="departmentName"
                        resultLimit={100}
                        label="Відділення де знаходиться дитина"
                        filters={educationCenterId ? { educationCenterId } : undefined}
                        required={!additionalProperties.isReadOnly}
                        readOnly={additionalProperties.isReadOnly || !educationCenterId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsDatePicker
                        labelPosition="top"
                        source={getSource(ChildFormFields.EDUCATIONAL_AGREEMENT_DATE)}
                        label="Дата згоди на надання освітніх послуг"
                        required={!additionalProperties.isReadOnly}
                        disabled={additionalProperties.isReadOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ShsDatePicker
                        labelPosition="top"
                        source={getSource(ChildFormFields.EDUCATIONAL_SERVICE_END)}
                        label="Дата завершення надання освітніх послуг"
                        disabled={additionalProperties.isReadOnly}
                    />
                </Grid>
                <Box width="100%" />
                <Grid item xs={12}>
                    <ShsRadioButtonGroup
                        source={`${getSource(ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS)}.enabled`}
                        label="Особа з особливими освітніми потребами"
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        choices={YesNoChoices}
                        optionValue="value"
                        onChange={(value: unknown) => {
                            if (!value) {
                                formContext.setValue('visit.specialRequirements.parameters', []);
                            }
                        }}
                        readOnly={additionalProperties.isReadOnly}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const specialRequirements = extractValue(
                                formData,
                                getSource(ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS)
                            );
                            const isSpecialRequirements = specialRequirements['enabled'];

                            return (
                                isSpecialRequirements && (
                                    <Box sx={{ mt: -2 }}>
                                        <Typography
                                            component="div"
                                            fontWeight="400"
                                            color={Theme.palette.primary.optional}>
                                            Адаптація освітнього процесу під потреби учнів
                                        </Typography>
                                        <CheckboxGroupInput
                                            sx={{ color: Theme.palette.primary.midnightBlue }}
                                            source={`${getSource(ChildFormFields.EDUCATIONAL_SPECIAL_REQUIREMENTS)}.parameters`}
                                            choices={SpecialEducationRequirementsList}
                                            row={false}
                                            label={''}
                                            readOnly={additionalProperties.isReadOnly}
                                        />
                                    </Box>
                                )
                            );
                        }}
                    </FormDataConsumer>
                    <Divider sx={{ mb: 2, mt: 0 }} />
                </Grid>

                <Grid item xs={12}>
                    <ShsRadioButtonGroup
                        source={getSource(ChildFormFields.DISABILITY)}
                        label="Дитина має інвалідність"
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        choices={YesNoChoices}
                        optionValue="value"
                        onChange={(value: unknown) => {
                            if (!value) {
                                formContext.setValue('visit.disabilityGroup', null);
                                formContext.setValue('visit.disabilityReason', null);
                            }
                        }}
                        readOnly={additionalProperties.isReadOnly}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const isDisabilityPresent = extractValue(formData, getSource(ChildFormFields.DISABILITY));

                            return (
                                isDisabilityPresent && (
                                    <>
                                        <Box sx={{ mt: 0 }}>
                                            <Typography
                                                component="div"
                                                fontWeight="400"
                                                color={Theme.palette.primary.optional}>
                                                Група інвалідності
                                            </Typography>
                                            <ShsRadioButtonGroup
                                                source={getSource(ChildFormFields.DISABILITY_GROUP)}
                                                sx={{ color: Theme.palette.primary.midnightBlue }}
                                                choices={DisabilityGroupList}
                                                label={''}
                                                required={!additionalProperties.isReadOnly}
                                                readOnly={additionalProperties.isReadOnly}
                                            />
                                        </Box>
                                        <Box sx={{ mt: 0 }}>
                                            <Typography
                                                component="div"
                                                fontWeight="400"
                                                color={Theme.palette.primary.optional}>
                                                Причина інвалідності
                                            </Typography>
                                            <ShsRadioButtonGroup
                                                source={getSource(ChildFormFields.DISABILITY_REASON)}
                                                sx={{ color: Theme.palette.primary.midnightBlue }}
                                                choices={DisabilityReasonList}
                                                label={''}
                                                required={!additionalProperties.isReadOnly}
                                                readOnly={additionalProperties.isReadOnly}
                                            />
                                        </Box>
                                    </>
                                )
                            );
                        }}
                    </FormDataConsumer>
                    <Divider sx={{ mb: 2, mt: 0 }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="div" mb={2} fontWeight="500" color={Theme.palette.primary.midnightBlue}>
                        Соціальний статус
                    </Typography>
                    <Box display="flex" flexDirection="column">
                        <Typography component="span" mb={1} fontWeight="400" color={Theme.palette.primary.optional}>
                            Відмітьте пункти, які відносяться до дитини
                        </Typography>
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={getSource(ChildFormFields.ORPHAN)}
                            label={SocialStatusLabels[ChildFormFields.ORPHAN]}
                            disabled={additionalProperties.isReadOnly}
                        />
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={getSource(ChildFormFields.DISPLACED)}
                            label={SocialStatusLabels[ChildFormFields.DISPLACED]}
                            disabled={additionalProperties.isReadOnly}
                        />
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={getSource(ChildFormFields.WAR_VICTIM)}
                            label={SocialStatusLabels[ChildFormFields.WAR_VICTIM]}
                            disabled={additionalProperties.isReadOnly}
                        />
                        <ShsCheckbox
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            source={getSource(ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES)}
                            label={SocialStatusLabels[ChildFormFields.COMPLICATED_LIFE_CIRCUMSTANCES]}
                            disabled={additionalProperties.isReadOnly}
                        />
                        <FormControlLabel
                            sx={{ color: Theme.palette.primary.midnightBlue }}
                            control={
                                <Checkbox
                                    checked={isOtherSocialStatus}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    onChange={() => {
                                        if (isOtherSocialStatus) {
                                            formContext.setValue('visit.otherSocialStatus', '');
                                        }
                                        setIsOtherSocialStatus(!isOtherSocialStatus);
                                    }}
                                    disabled={additionalProperties.isReadOnly}
                                />
                            }
                            label="Інше"
                        />
                    </Box>
                    {isOtherSocialStatus && (
                        <ShsTextInput
                            source={getSource(ChildFormFields.OTHER_SOCIAL_STATUS)}
                            label="."
                            placeholder="Напишіть"
                            label-sx={{ height: 0, visibility: 'hidden' }}
                            required={!additionalProperties.isReadOnly}
                            inputProps={{ disabled: additionalProperties.isReadOnly }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <ShsRadioButtonGroup
                        sx={{ color: Theme.palette.primary.midnightBlue }}
                        source={getSource(ChildFormFields.MEDIA_RECORDING_AGREEMENT)}
                        label="Згода на проведення фото-, кіно-, теле- та відеозйомки дитини"
                        choices={YesNoChoices}
                        optionValue="value"
                        onChange={(value: unknown) => {
                            if (!value) {
                                formContext.setValue('visit.mediaRecordingLink', null);
                            }
                        }}
                        readOnly={additionalProperties.isReadOnly}
                    />
                    <FormDataConsumer>
                        {({ formData }) => {
                            const isAgreement = extractValue(
                                formData,
                                getSource(ChildFormFields.MEDIA_RECORDING_AGREEMENT)
                            );

                            return (
                                isAgreement && (
                                    <Box mt={-2}>
                                        <ShsTextInput
                                            source={getSource(ChildFormFields.MEDIA_RECORDING_LINK)}
                                            label="."
                                            placeholder="Вкажіть лінк на файл згоди"
                                            label-sx={{ height: 0, visibility: 'hidden' }}
                                            inputProps={{ disabled: additionalProperties.isReadOnly }}
                                        />
                                    </Box>
                                )
                            );
                        }}
                    </FormDataConsumer>
                    <Box>
                        {isShowHistoryItem ? (
                            historyItemInfo && (
                                <>
                                    <div className="text_field__label">Додаткова інформація, яку необхідно знати</div>
                                    <Typography color={Theme.palette.primary.optional}>{historyItemInfo}</Typography>
                                </>
                            )
                        ) : (
                            <ShsTextInput
                                source={getSource(ChildFormFields.CHILD_INFO)}
                                label="Додаткова інформація, яку необхідно знати"
                                inputProps={{ maxLength: 255, disabled: additionalProperties.isReadOnly }}
                                multiline
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        );
    };
    const EducationTypography = ({ title = '' }) => {
        return (
            <Typography variant="h5" component="h5" mb={3} fontWeight="600" color={Theme.palette.primary.midnightBlue}>
                {title}
            </Typography>
        );
    };

    const EducationalHistory = () => {
        return (
            <>
                {visitHistory?.map((visit: ChildVisit, visitHistoryIndex: number) => (
                    <React.Fragment key={visit[ChildFormFields.ID]}>
                        <Grid item xs={12}>
                            <Divider sx={{ mb: 2, mt: 0 }} />
                            <Accordion disableGutters sx={{ boxShadow: 'none' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    sx={{
                                        flexDirection: 'row-reverse',
                                        gap: '14px'
                                    }}>
                                    <Typography>{visit?.educationCenter?.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '0' }}>
                                    <EducationalGrid isReadOnly i={visitHistoryIndex} />
                                </AccordionDetails>
                            </Accordion>
                            <Divider sx={{ mb: 2, mt: 2 }} />
                        </Grid>
                    </React.Fragment>
                ))}
            </>
        );
    };

    return (
        <>
            {!!visitHistory.length && (
                <>
                    <EducationTypography title="Відомості про освіту дитини в школі супергероїв" />
                    <EducationalHistory />
                </>
            )}
            <EducationTypography
                title={
                    isInactive ? 'Нове навчання в школі супергероїв' : 'Відомості про освіту дитини в школі супергероїв'
                }
            />
            <EducationalGrid />
        </>
    );
};

export default EducationChildData;
