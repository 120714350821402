import React, { useEffect, useState } from 'react';
import { Create, FormGroupContextProvider, TabbedForm, useCreatePath, useRedirect } from 'react-admin';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { editContainerStyles } from 'src/styles';
import { palette, Theme } from 'src/Theme';
import { ReactComponent as ChevronRight } from 'src/assets/chevron-right.svg';
import { addBeforeUnloadListener } from 'src/utils/employees/navigation';

import { EmployeeType } from './types';
import EmploymentTypeForm from './form/EmploymentTypeForm';
import { TabLabel } from './components/TabLabel';
import EmployeeDocumentsForm from './form/EmployeeDocumentsForm';
import EmployeePersonalDetailsForm from './form/EmployeePersonalDetailsForm';
import tabGroups from './const/tab-groups';
import { ShsTabbedFormToolbar } from '../common/ShsTabbedFormToolbar';
import useTabbedFormNavigation from '../common/ShsTabbedFormToolbar/useTabIndex';
import { CancelEditEmployeeModal } from './modals/CancelEditEmployeeModal';

interface Schedule {
    [key: string]: Array<{ from?: string; to?: string }>;
}

interface EmployeeCenter {
    educationCenterId: number;
    dateOfStarting: string;
    staffMember: boolean;
    roles: string[];
    roleDescription?: string;
    rate?: string | number;
    civilLawNatureContract: boolean;
    dateOfEnding?: string;
    possibleWorkSchedule: Schedule;
    subjectIds: number[];
}

interface EmployeeCenterInput {
    educationCenterId: number;
    dateOfStarting?: string;
    dateOfStartingCLNC?: string;
    dateOfEnding?: string;
    staffMember: boolean;
    civilLawNatureContract: boolean;
    roles?: string[];
    rolesCLNC?: string[];
    rate?: string | number;
    possibleWorkSchedule?: Schedule;
    subjectIds?: number[];
}

const transformEmployeeCenter = (center: EmployeeCenterInput): EmployeeCenter[] => {
    const centers: EmployeeCenter[] = [];

    if (center.staffMember) {
        centers.push({
            educationCenterId: center.educationCenterId,
            dateOfStarting: center.dateOfStarting || '',
            staffMember: true,
            civilLawNatureContract: false,
            roles: center.roles || [],
            rate: center.rate,
            possibleWorkSchedule: center.possibleWorkSchedule || {},
            subjectIds: []
        });
    }

    if (center.civilLawNatureContract) {
        centers.push({
            educationCenterId: center.educationCenterId,
            dateOfStarting: center.dateOfStartingCLNC || '',
            dateOfEnding: center.dateOfEnding,
            staffMember: false,
            civilLawNatureContract: true,
            roles: center.rolesCLNC || [],
            possibleWorkSchedule: center.possibleWorkSchedule || {},
            subjectIds: center.subjectIds || []
        });
    }

    return centers;
};

const transformFormData = (formData: any) => {
    const allCenters = formData.employeeCenters.flatMap(transformEmployeeCenter);

    const transformedData = {
        ...formData,
        employeeCenters: allCenters,
        employeeDetails: {
            ...formData.employeeDetails,
            medicalCheckup: formData.employeeDetails.medicalCheckup
                ? new Date(formData.employeeDetails.medicalCheckup).toISOString().split('T')[0]
                : undefined,
            diplomas: formData.employeeDetails.diplomas || [],
            refresherCertificates: formData.employeeDetails.refresherCertificates || [],
            additionalDocuments: formData.employeeDetails.additionalDocuments || []
        }
    };

    transformedData.employeeCenters = transformedData.employeeCenters.map((center: EmployeeCenter) => {
        const { ...rest } = center;
        return Object.fromEntries(Object.entries(rest).filter(([_, value]) => value !== undefined && value !== null));
    });

    return transformedData;
};

const tabbedFormStyles = {
    'a.form-tab.MuiTab-labelIcon': {
        flex: '0 1 auto'
    },
    '.MuiTabs-indicator': {
        display: 'none'
    },
    '.Mui-selected.Mui-disabled': {
        color: Theme.palette.primary.main
    },
    '.MuiCardContent-root ': {
        padding: '0 !important',
        background: 'transparent !important'
    }
};

const tabStyles = {
    padding: 0,
    '&.MuiTab-labelIcon': {
        flexFlow: 'row-reverse'
    },
    '.MuiTab-iconWrapper': {
        margin: '0 32px'
    }
};

/**
 * Employee Create page
 */
export const EmployeeCreate: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const { tabIndex } = useTabbedFormNavigation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Show default confirm reload modal on page reload
     */
    useEffect(() => {
        const removeBeforeUnloadListener = addBeforeUnloadListener();
        return () => {
            removeBeforeUnloadListener();
        };
    }, []);

    const transform = (data: any) => {
        try {
            return transformFormData(data);
        } catch (error) {
            console.error('Error transforming form data:', error);
        }
    };

    /**
     * Opens the cancel edit modal
     */
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    /**
     * Closes the cancel edit modal
     */
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const onSuccess = () => {
        redirect(createPath({ resource: 'employees', type: 'list' }));
    };

    return (
        <Container sx={editContainerStyles} maxWidth={false}>
            <ShsReturnButton
                sx={{ color: Theme.palette.primary.grayBluish, fontSize: '16px' }}
                label="Назад до списку працівників"
                onClick={handleOpenModal}
            />
            <Create<EmployeeType> mutationOptions={{ onSuccess }} transform={transform}>
                <Typography fontWeight="700" fontSize={'40px'} color={Theme.palette.primary.midnightBlue}>
                    Додати працівника
                </Typography>
                <TabbedForm
                    toolbar={<ShsTabbedFormToolbar tabGroups={tabGroups} saveTitle="Додати працівника" />}
                    sx={{
                        ...tabbedFormStyles,
                        '& .MuiCardContent-root': {
                            background: 'red'
                        }
                    }}
                    mode="onBlur">
                    <TabbedForm.Tab
                        label={<TabLabel label="Персональна інформація" index={0} />}
                        icon={<ChevronRight stroke={palette.primary.gray} />}
                        sx={tabStyles}
                        disabled={tabIndex === 0}>
                        <FormGroupContextProvider name={tabGroups[0]}>
                            <EmployeePersonalDetailsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label={<TabLabel label="Документи" index={1} />}
                        icon={<ChevronRight stroke={palette.primary.gray} />}
                        sx={tabStyles}
                        disabled={tabIndex < 1}>
                        <FormGroupContextProvider name={tabGroups[1]}>
                            <EmployeeDocumentsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label={<TabLabel label="Працевлаштування" index={2} />}
                        sx={tabStyles}
                        disabled={tabIndex < 2}>
                        <FormGroupContextProvider name={tabGroups[2]}>
                            <EmploymentTypeForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    {isModalOpen && (
                        <CancelEditEmployeeModal
                            isOpen={isModalOpen}
                            onSuccess={onSuccess}
                            onClose={handleCloseModal}
                            handleRedirect={onSuccess}
                        />
                    )}
                </TabbedForm>
            </Create>
        </Container>
    );
};
