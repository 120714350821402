import { IconButton, Typography } from '@mui/material';
import { ReactComponent as Logo } from 'src/assets/notification.svg';
import { WithRole } from 'src/components/common';
import { AdminGroup } from 'src/constants/roles';
import { ADMIN_MESSAGE, ADMIN_TITLE, EMPLOYEE_MESSAGE, EMPLOYEE_TITLE } from './constants';
import { Theme } from 'src/Theme';
import CircleIcon from '@mui/icons-material/Circle';
import { useState } from 'react';

type ShsAlertProps = {
    type?: string;
    employeeId?: number;
    name?: string;
    surname?: string;
    patronymic?: string;
    medicalCheckupExpirationDate?: string;
    daysToExpiration?: number;
    checked?: boolean;
    handleClickMarker?: (id: number) => void;
};

const ShsAlert = ({
    type,
    name,
    surname,
    patronymic,
    medicalCheckupExpirationDate,
    daysToExpiration,
    employeeId,
    checked = false,
    handleClickMarker = () => {}
}: ShsAlertProps) => {
    const [readStatus, setReadStatus] = useState<boolean>(checked);

    const readHandler = (id: number) => {
        handleClickMarker(id);
        setReadStatus((status) => !status);
    };

    return name && surname && medicalCheckupExpirationDate && daysToExpiration && employeeId ? (
        <div key={employeeId} style={{ width: '450px', background: '#fff', padding: '0px 16px' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '16px',
                    paddingBottom: '10px'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px'
                    }}>
                    <div
                        style={{
                            width: '24px',
                            height: '24px'
                        }}>
                        <Logo fontSize="inherit" />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px'
                        }}>
                        <Typography
                            fontSize={14}
                            fontWeight={600}
                            color={checked ? Theme.palette.primary.grayBluish : Theme.palette.primary.midnightBlue}>
                            <WithRole roles={[...AdminGroup]} orElse={EMPLOYEE_TITLE}>
                                {ADMIN_TITLE(type, name, surname, patronymic)}
                            </WithRole>
                        </Typography>
                        <Typography fontSize={11} fontWeight={400} color={Theme.palette.primary.grayBluish}>
                            <WithRole
                                roles={[...AdminGroup]}
                                orElse={EMPLOYEE_MESSAGE(daysToExpiration, medicalCheckupExpirationDate)}>
                                {ADMIN_MESSAGE(
                                    type,
                                    name,
                                    surname,
                                    patronymic,
                                    daysToExpiration,
                                    medicalCheckupExpirationDate
                                )}
                            </WithRole>
                        </Typography>
                    </div>
                </div>
                <IconButton size="large" onClick={() => readHandler(employeeId)}>
                    <CircleIcon color={!readStatus ? 'primary' : 'disabled'} />
                </IconButton>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default ShsAlert;
