import React, { useState, useEffect } from 'react';
import axiosApiInstance from '../../api/axiosConfig';
import QRCode from 'qrcode.react';
import CountDown from './CountDownTimer';

interface DiiaQrCodeProps {
    showQrCode: boolean;
}

const QrCode: React.FC<DiiaQrCodeProps> = ({ showQrCode }) => {
    const [refreshQrKey, setrefreshQrKey] = useState<number>(1);
    const [refreshCountDownKey, setrefreshCountDownKey] = useState<number>(1);
    const [qrData, setQrData] = useState<string>('');
    const [requestId, setRequestId] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    let initialCountDownValue: number = 180;

    const refreshQrCode = () => {
        setrefreshQrKey(refreshQrKey + 1);
    };

    useEffect(() => {
        if (showQrCode) {
            axiosApiInstance
                .get('/auth/diia')
                .then((response) => {
                    setQrData(response.data.deeplink);
                    setRequestId(response.data.requestId);
                    setrefreshCountDownKey(refreshCountDownKey + 1);
                    setErrorMessage('');
                    localStorage.setItem('readNotifications', '');
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [showQrCode, refreshQrKey]);

    return (
        <div className="diia-qrcode">
            <div>
                {showQrCode && <div className="login-diia-info">Відскануйте QR-код сканером у застосунку Дія</div>}
            </div>
            <div className="qr-code">{showQrCode && qrData && <QRCode value={qrData} width={216} height={216} />}</div>

            {showQrCode && qrData && (
                <CountDown
                    refreshCountDownKey={refreshCountDownKey}
                    initialCountDownValue={initialCountDownValue}
                    requestId={requestId}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}

            <div className="login-diia-button-container">
                {showQrCode && qrData && (
                    <button className="login-diia-refresh" onClick={refreshQrCode}>
                        Оновити QR-код
                    </button>
                )}
            </div>
        </div>
    );
};

export default QrCode;
