import React, { useState } from 'react';
import {
    Edit,
    FormGroupContextProvider,
    TabbedForm,
    useCreatePath,
    useEditController,
    useNotify,
    useRedirect
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Typography } from '@mui/material';

import { ShsReturnButton } from 'src/components/common';
import { Theme } from 'src/Theme';
import { employeeEditContainerStyles } from 'src/styles/components/employee/employee';

import { EmployeeType } from './types';
import EmploymentTypeForm from './form/EmploymentTypeForm';
import EmployeeDocumentsForm from './form/EmployeeDocumentsForm';
import EmployeePersonalDetailsForm from './form/EmployeePersonalDetailsForm';
import tabGroups from './const/tab-groups';
import EmployeeEditToolbar from './form/EmployeeEditToolbar';
import { CancelEditEmployeeModal } from './modals/CancelEditEmployeeModal';

/**
 * Employee Edit page with three tabs
 */
const EmployeeEdit: React.FC = () => {
    const form = useForm();

    return (
        <FormProvider {...form}>
            <InnerEmployeeEdit />
        </FormProvider>
    );
};

const InnerEmployeeEdit: React.FC = () => {
    const redirect = useRedirect();
    const createPath = useCreatePath();
    const notify = useNotify();
    const { record } = useEditController();
    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Executed on successful edit and displays a success notification
     */
    const onSuccess = () => {
        notify('Редагування працівника пройшло успішно', {
            type: 'success',
            autoHideDuration: 2500
        });
    };

    /**
     * Opens the cancel edit modal
     */
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    /**
     * Closes the cancel edit modal
     */
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    /**
     * Redirects to the employee's profile page after successful edit
     */
    const handleRedirect = () => {
        redirect(createPath({ resource: `employees/${record.id}/show`, type: 'show' }));
    };

    return (
        <Container sx={employeeEditContainerStyles} maxWidth={false}>
            <ShsReturnButton
                sx={{ color: Theme.palette.primary.grayBluish, fontSize: '1em' }}
                label="Назад до профілю працівника"
                onClick={handleOpenModal}
            />
            <Edit<EmployeeType> actions={false} redirect="show" mutationOptions={{ onSuccess }}>
                <Typography variant="h4" component="h3" mb={4} fontWeight="700">
                    Редагування працівника
                </Typography>
                <TabbedForm
                    toolbar={
                        <EmployeeEditToolbar
                            deleteLabel="Видалити працівника"
                            submitLabel="Зберегти зміни"
                            enableCancelButton={false}
                            editMode
                            handleCancelModalFromParent={handleCloseModal}
                            onCancel={handleRedirect}
                            onSuccess={onSuccess}
                        />
                    }>
                    <TabbedForm.Tab label="Персональна інформація">
                        <FormGroupContextProvider name={tabGroups[0]}>
                            <EmployeePersonalDetailsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Документи">
                        <FormGroupContextProvider name={tabGroups[1]}>
                            <EmployeeDocumentsForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="Працевлаштування">
                        <FormGroupContextProvider name={tabGroups[2]}>
                            <EmploymentTypeForm />
                        </FormGroupContextProvider>
                    </TabbedForm.Tab>
                    {isModalOpen && (
                        <CancelEditEmployeeModal
                            isOpen={isModalOpen}
                            isEdit={true}
                            handleRedirect={handleRedirect}
                            onClose={handleCloseModal}
                            onSuccess={onSuccess}
                        />
                    )}
                </TabbedForm>
            </Edit>
        </Container>
    );
};

export default EmployeeEdit;
